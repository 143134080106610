@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap");

.gbvkmkrf {
  font-family: "Roboto", sans-serif;
  text-wrap: balance;
  -webkit-tap-highlight-color: transparent;
  padding:0;
  margin: 0;
}

body{

  width: 100%;

}
