@keyframes force-rendering {
  0%   { opacity: 0.9999; }
  100% { opacity: 1; }
}

body {
  margin: 0;
  animation: force-rendering 0.1s;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translateZ(0); /* Forces GPU repaint */
  opacity: 1;
  will-change: transform;
  padding: 0;
  width: 100%;
}

